











































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class TramAnimation extends Vue {
  @Prop({ type: Number, default: 150 }) height: number
  @Prop({ type: Number, default: 150 }) width: number
  @Prop({ type: String, default: '100px auto' }) margin: string
  @Prop({ type: Boolean, default: false }) color: boolean
}
