












import { Component, Prop, Model, PropSync, Vue } from 'vue-property-decorator'
import AxSelect from './AxSelect.vue'
import moment from 'moment'
import range from '../utils/range'

@Component({
  components: {
    AxSelect,
  },
})
export default class AxBirthdayPickerYear extends Vue {
  @Prop({ type: Boolean, default: false }) hasError: boolean
  @Prop({ type: Number, default: 1900 }) startYear: number
  @Prop({ type: Number, default: 2020 }) endYear: number
  @Model('update:value', { type: Number, required: false }) readonly value!: number | null
  @PropSync('value', { default: null, required: false }) yearValue!: number | null

  protected selectedYear: any = null

  private onSelectChanged(selectOption) {
    this.$emit('update:value', selectOption.value)
  }

  get years() {
    return range({ start: this.endYear, end: this.startYear, ascending: false })
  }

  get selectValues() {
    return this.years.map((v) => {
      return {
        text: v,
        value: v,
      }
    })
  }
}
