
































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class FamilyMembers extends Vue {
  @Prop({ required: true, default: null }) members: [] | any
  @Prop({ default: false }) showAvatars: boolean
  @Prop({ default: false }) showTopDivider: boolean

  private photoStyle(member) {
    return `background-image: url(data:image/jpeg;base64,${member.photo})`
  }

  private addFamilyMember() {
    this.$emit('add-family-member')
  }
}
