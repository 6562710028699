












import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class AppSidebar extends Vue {
  @Getter('settings/languageSettings') language
  @Getter('settings/theme') theme

  get titleStyle() {
    if (this.theme && this.theme.general && this.theme.general.showArrivalTitleAsImage) {
      return `background-image: url('${this.theme.general.arrivalTitleImageUrl}');background-size: 100%;font-size: 0;height: 60px;`
    }

    return ''
  }
}
