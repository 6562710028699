
















import { Action, Getter, Mutation } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import Contact from '@/Common/interfaces/Contact'
import FamilyMemberForm from '../components/FamilyMemberForm.vue'
import FamilyMembers from '../components/FamilyMembers.vue'
import ProfileService from '../services/ProfileService'
import store from '@/Common/store/index'
import GTMEvent from '@/Common/interfaces/GTMEvent'
import AxBirthdayPicker from '@/Common/components/AxBirthdayPicker.vue'

@Component({
  components: {
    AxBirthdayPicker,
    FamilyMembers,
    FamilyMemberForm,
  },
})
export default class AddFamilyMember extends Vue {
  @Action('profile/fetchProfile') fetchProfileInfo
  @Action('trackEvent') trackEvent
  @Getter('profile/contacts') familyMembers
  @Getter('profile/headOfHouseHoldContact') headOfHouseHold

  serviceError: ServiceError | null = null

  private async fetchProfile() {
    await this.fetchProfileInfo()
  }

  private goBack() {
    this.$router.push({ name: 'home' }).catch(() => {
      // ... No catch yet
    })
  }

  private addFamilyMemberEvent({ eventAction, eventData = {} }) {
    const signatureEvent = {
      event: 'arrival_event',
      category: 'Profile',
      action: eventAction,
      noninteraction: false,
      ...eventData,
    }
    this.trackEvent(signatureEvent as GTMEvent)
  }

  private async addFamilyMember(contact: Contact) {
    if (this.headOfHouseHold) {
      contact.parentContactId = this.headOfHouseHold.id
      await ProfileService.AddFamilyMember(contact)
      this.addFamilyMemberEvent({ eventAction: 'Add Family Member' })
      this.goBack()
    }
  }
}
