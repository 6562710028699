




























































import { required } from 'vuelidate/lib/validators'
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import Collection from '@/Common/interfaces/Collection'
import CollectionService from '@/Common/services/CollectionService'
import CheckPassRequest from '../interfaces/CheckPassRequest'
import CollectionItem from '@/Common/interfaces/CollectionItem'
import { Getter } from 'vuex-class'
import moment from 'moment'

@Component({})
export default class PassCheckerForm extends Vue {
  @Getter('settings/languageSettings') language

  @Prop({ required: true }) isLoading: boolean = false

  private accessLocationsId = 1
  private passNumber: string = ''
  private date: string = new Date().toISOString().substr(0, 10)
  private dateFormatted = this.formatDate(this.date)
  private dateMenu: boolean = false
  private locationCollection: Collection | null = null
  private locationId: string | null = null

  validations() {
    return {
      passNumber: { required },
    }
  }

  @Emit('onSubmitted')
  private async checkPass() {
    this.$v!.$touch()

    if (!this.$v!.$error) {
      if (this.locationId) {
        const request: CheckPassRequest = {
          date: new Date(this.date),
          locationId: +this.locationId,
          passNumber: this.passNumber,
        }
        return request
      }
    }
  }

  public created() {
    this.getCollections()
  }

  private formatDate(date) {
    const d = new Date(date)
    return date ? moment(d).add(1, 'days').format('MMMM D, YYYY') : ''
  }

  private async getCollections() {
    const collections = await CollectionService.FetchCollections()
    this.locationCollection = collections.filter((c) => c.id == this.accessLocationsId)[0]

    if (this.locationCollection!.collectionItems!.length == 1) {
      const firstLocation = this.locationCollection!.collectionItems[0]
      this.locationId = firstLocation.value
    }
  }

  private getErrorMessages(vuelidateObject, message) {
    return vuelidateObject.$error ? [`${message}`] : []
  }

  @Watch('date')
  onDateChanged(value: string) {
    this.dateFormatted = this.formatDate(value)
  }
}
