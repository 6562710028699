












import { Component, Prop, Model, PropSync, Vue } from 'vue-property-decorator'
import AxSelect from './AxSelect.vue'
import moment from 'moment'
import range from '../utils/range'

@Component({
  components: {
    AxSelect,
  },
})
export default class AxBirthdayPickerMonth extends Vue {
  @Prop({ type: Boolean, default: true }) showMonthNames: boolean
  @Prop({ type: Boolean, default: false }) hasError: boolean
  @Model('update:value', { type: Number, required: false }) readonly value!: number | null
  @PropSync('value', { default: null, required: false }) monthValue!: number | null

  protected selectedMonth: any = null

  protected range(start, end) {
    const rangeArr: number[] = []
    while (start <= end) {
      rangeArr.push(start++)
    }
    return rangeArr
  }

  protected onSelectChanged(selectOption) {
    this.$emit('update:value', selectOption.value)
  }

  get monthNames(): string[] {
    const monthArray = this.range(0, 11)
    return monthArray.map((m) => moment({ year: 1900, day: 1, month: m }).format('MMM'))
  }

  get monthNumbers(): number[] {
    return range({ start: 1, end: 12 })
  }

  get monthValues(): any[] {
    if (this.showMonthNames) {
      return this.monthNumbers.map((v, vIndex) => `${v} - ${this.monthNames[vIndex]}`)
    } else {
      return this.monthNumbers
    }
  }

  get selectValues() {
    return this.monthValues.map((v, vIndex) => {
      return {
        text: v,
        value: vIndex + 1,
      }
    })
  }
}
