









































import { Component, Prop, PropSync, Watch, Vue, Emit } from 'vue-property-decorator'
import type BLEContact from '../viewmodels/BLEContact'
import { required, minLength, email, helpers, requiredIf } from 'vuelidate/lib/validators'
import ETicketDeliveryMode from '../enums/ETicketDeliveryMode'

const validateIfPhoneNumber = (vm, validateIf, validator) =>
  helpers.withParams({ type: 'validatedIf' }, (value, parentVm) => {
    return vm.isPhoneNumber === validateIf ? validator(value) : true
  })

@Component({
  name: 'ble-desktop-send-link',
})
export default class BLEDesktopSendLink extends Vue {
  @Prop({ type: String, default: 'Phone Number or Email Address' }) inputPlaceholder: string
  @Prop({ type: Boolean, default: true }) primaryAction: boolean
  @PropSync('member', { type: Object }) linkMember!: BLEContact

  validations() {
    return {
      member: {
        newRecipient: {
          required,
          email: validateIfPhoneNumber(this, false, email),
          minLength: validateIfPhoneNumber(this, true, minLength(10)),
        },
      },
    }
  }

  get phoneMask(): string {
    return this.isPhoneNumber ? '(###)###-####' : ''
  }

  @Emit('sendLink')
  sendLink() {
    this.$v.$touch()

    const valid = !this.$v.$error
    return valid
  }

  @Watch('isPhoneNumber')
  isPhoneNumberChanged(isPhoneNumber: boolean) {
    Vue.nextTick(() => {
      const fieldLength = this.linkMember?.newRecipient?.length || 0
      if (isPhoneNumber) {
        this.linkMember.newDeliveryMode = ETicketDeliveryMode.SMS
        const phoneControl = document.querySelector('#phone-input input')
        const caretPos = isPhoneNumber ? fieldLength + 3 : fieldLength
        this.setCaretPosition(phoneControl, caretPos)
      } else {
        this.linkMember.newDeliveryMode = ETicketDeliveryMode.Email
        const emailControl = document.querySelector('#email-address')
        const caretPos = fieldLength
        this.setCaretPosition(emailControl, caretPos)
      }
    })
  }

  get isPhoneNumber(): boolean {
    // Once the entry starts with 4 digits consecutively, assume it is a phone number
    const regex = /^\d{5}/

    return (this.linkMember?.newRecipient && !!this.linkMember?.newRecipient.match(regex)) || false
  }

  setCaretPosition(ctrl, pos) {
    // Set focus to the given control and cursor position
    ctrl.focus()
    ctrl.setSelectionRange(pos, pos)
  }

  mounted() {
    // Because the form defaults to email, select and set focus to this field
    const emailControl = document.querySelector('#email-address')
    this.linkMember.newDeliveryMode = ETicketDeliveryMode.Email
    Vue.nextTick(() => {
      this.setCaretPosition(emailControl, 0)
    })
  }
}
