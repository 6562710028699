








































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import ContactTask from '../../Task/interfaces/ContactTask'
import VueQrcode from 'vue-qrcode'
import type PrintableMedia from '../../Task/interfaces/PrintableMedia'
import Ticket from './Ticket.vue'
import CustomerPrintableMedia from '../../Task/interfaces/customerPrintableMedia'
import PrintableMediaItem from '../../Task/interfaces/printableMediaItem'
import GTMEvent from '@/Common/interfaces/GTMEvent'
import ProfileService from '../services/ProfileService'

@Component({
  components: {
    VueQrcode,
    Ticket,
  },
})
export default class PrintableMediaMembers extends Vue {
  @Prop({ default: null }) printableMedia: PrintableMedia

  @Getter('settings/languageSettings') language
  @Getter('settings/theme') theme
  @Action('trackEvent') trackEvent

  private isiOSDevice: boolean = false

  get displayMedia() {
    return this.qrCodeCount > 0 && (this.displayMobile || this.displayDesktop)
  }

  get displayMobile() {
    const displaySetting = this.theme.mediaCard ? this.theme.mediaCard.showMediaPasses : 'None'
    return displaySetting.toUpperCase().includes('MOBILE')
  }

  get displayDesktop() {
    const displaySetting = this.theme.mediaCard ? this.theme.mediaCard.showMediaPasses : 'None'
    return displaySetting.toUpperCase().includes('DESKTOP')
  }

  created() {
    this.isiOSDevice = this.$device.ios.valueOf()
  }

  get mediaTitle() {
    if (this.language && this.language.home.qrCodesTitle) {
      const title = this.language.home!.qrCodesTitle.replace('{QRCodeCount}', this.qrCodeCount)
      return title
    }

    return `You have ${this.qrCodeCount} media item${this.qrCodeCount > 1 ? 's' : ''} available`
  }

  get mediaMessage() {
    return `Scan these QR codes at a pickup location to print your media.`
  }

  get qrCodes() {
    const codes = []
    if (this.printableMediaItems) {
      this.printableMediaItems.forEach((i, index) => {
        const uniqueMediaItems = []
        const qrs = i.mediaItems.map((q) => q.printableMediaId)
        const qrSet = new Set(qrs)
        const uniqueQrCodes = qrSet.forEach((s) => {
          const products = i.mediaItems.filter((t) => {
            if (t.printableMediaId === s) {
              return t.productHeader
            }
          })
          const productNames = products.map((p) => p.productHeader)

          const newCode = {
            contact: i.contact,
            products: productNames,
            printableMediaId: s,
            queryString: `?ipcode=${i.contact.contactId}&firstName=${i.contact.firstName}&lastName=${i.contact.lastName}&externalOrderId=${s}`,
          }
          Vue.set(codes, index, newCode)
        })
      })
    }

    return codes
  }

  get firstQRCode() {
    if (this.qrCodes) {
      const headOfHousehold = this.qrCodes.find((qr: any) => {
        if (qr.contact.isHeadOfHouseHold) {
          return qr
        }
      })

      return headOfHousehold || this.qrCodes[0]
    }

    return null
  }

  get qrCodeCount() {
    return this.qrCodes ? this.qrCodes.length : 0
  }

  get printableMediaItems() {
    if (this.printableMedia) {
      const mediaArray = this.printableMedia.printableMediaCustomers.map((c) => {
        const items = c.customerTransaction.map((t) => {
          return {
            printableMediaId: t.printableMediaId,
            productHeader: t.productHeader,
          }
        })

        const customerMedia = {
          contact: c.contact,
          mediaItems: items,
        }

        return customerMedia
      })

      return mediaArray
    }
  }

  private onCarouselChange(cValue) {
    const carouselEvent = {
      event: null,
      category: 'Carousel',
      action: 'click',
      label: 'Printable Media Carousel',
      value: cValue,
      noninteraction: false,
    }
    this.trackEvent(carouselEvent as GTMEvent)
  }

  private async getGoogleWalletPass(qrCode) {
    const googlePassLink = await ProfileService.FetchGoogleWalletPassLink(
      qrCode.contact.contactId,
      qrCode.contact.firstName,
      qrCode.contact.lastName,
      qrCode.printableMediaId
    )

    if (typeof googlePassLink === 'string') {
      window.open(googlePassLink, '_blank')
    } else {
      this.$emit('OpenPassError', googlePassLink)
    }
  }
}
