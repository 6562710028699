
















































import { Getter } from 'vuex-class'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import type ActivityContact from '@/Activity/interfaces/ActivityContact'
import moment from 'moment'
import PrintableMediaMembers from '../components/PrintableMediaMembers.vue'

@Component({
  components: {
    PrintableMediaMembers,
  },
})
export default class Overview extends Vue {
  @Getter('activity/activityContacts') activityContacts
  @Getter('location/currentLocation') currentLocation
  @Getter('task/contactTasks') contactTasks
  @Getter('oidcStore/oidcUser') user
  @Prop({ type: Boolean, default: false }) posTransactionsMode: boolean

  hasOpenPassError: boolean = false

  @Watch('hasOpenPassError')
  openPassErrorChanged(hasError) {
    if (hasError) {
      setTimeout(() => (this.hasOpenPassError = false), 3000)
    }
  }

  get overviewStyles() {
    if (this.defaultActivity) {
      const backgroundImage = this.defaultActivity.activity.imageUrl
        ? `url('${this.defaultActivity.activity.imageUrl}')`
        : `var(--activity-bkg-url)`
      const backgroundStyle = `background-image: ${backgroundImage};`
      return `${backgroundStyle}`
    }

    return ``
  }

  get todaysDate(): any {
    return moment().format('dddd, MMMM DD')
  }

  get defaultActivity(): ActivityContact | null {
    return this.activityContacts ? this.activityContacts[0] : null
  }

  get defaultActivityHasAges(): boolean {
    if (this.defaultActivity) {
      const hasAgeRanges = !!this.defaultActivity.activity.ageMin && !!this.defaultActivity.activity.ageMax
      return hasAgeRanges
    }
    return false
  }

  onOpenPassError() {
    this.hasOpenPassError = true
  }
}
