







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class NavigatorShare extends Vue {
  @Prop({ type: String, default: 'the_title_to_share' }) title: string
  @Prop({ type: String, default: 'the_text_to_share' }) text: string
  @Prop({ type: String, default: 'the_url_to_share', required: true }) url: string
  @Prop({ type: Function, default: null }) onError: (err: any) => void
  @Prop({ type: Function, default: null }) onSuccess: (succ: any) => void

  hasSlot() {
    /* tslint:disable:no-string-literal */
    return !!this.$slots['clickable']
  }

  share() {
    const data = {
      title: this.title,
      text: this.text,
      url: this.url,
    }
    if (navigator.share) {
      navigator
        .share(data)
        .then((succ) => {
          this.onSuccess(succ)
        })
        .catch((err) => {
          this.onError(err)
        })
    } else {
      if (this.onError) {
        this.onError('method not supported')
      }
    }
  }
}
