












import { Component, Prop, Model, PropSync, Vue } from 'vue-property-decorator'
import moment from 'moment'
import range from '../utils/range'
import AxSelect from './AxSelect.vue'

@Component({
  components: {
    AxSelect,
  },
})
export default class AxBirthdayPickerDay extends Vue {
  @Prop({ type: Boolean, default: false }) hasError: boolean
  @Prop({ type: Number, default: null }) currentMonth: number | null
  @Prop({ type: Number, default: null }) currentYear: number | null
  @Model('update:value', { type: Number, required: false }) readonly value!: number | null
  @PropSync('value', { default: null, required: false }) dayValue!: number | null

  private selectedDay: any = null

  private testDate: moment.Moment = moment()

  private onSelectChanged(selectOption) {
    this.$emit('update:value', selectOption.value)
  }

  get dayValues() {
    // Warning: If the month is not set, the default year is 1900 and wont return a leap year for Februrary
    const tYear = this.currentYear || 1900
    // If current month is not specified default to 31 - this could result in an invalid date
    const tMonth = this.currentMonth || 1
    this.testDate = moment({ year: tYear, month: tMonth - 1, day: 1 })

    return range({ start: 1, end: this.testDate.daysInMonth() })
  }

  get selectValues() {
    return this.dayValues.map((v) => {
      return {
        text: v,
        value: v,
      }
    })
  }
}
