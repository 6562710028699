

















import { Component, Prop, PropSync, Model, Watch, Vue } from 'vue-property-decorator'
import Icon from './Icon.vue'

@Component({
  components: {
    Icon,
  },
})
export default class ItemSelector extends Vue {
  @Prop({ required: true, default: 'checkbox' }) type: string
  @Prop({ required: false, default: false }) icon: boolean
  @Model('update:value', { type: Boolean, required: false }) readonly value!: boolean
  @PropSync('value', { default: false, required: false }) isChecked!: boolean
}
