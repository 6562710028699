


















































































































































































































































































































import { Action, Getter, Mutation, State } from 'vuex-class'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import BLEContact from '../viewmodels/BLEContact'
import BLEDesktopSendLink from '../components/BLEDesktopSendLink.vue'
import ETicketDelivery from '../models/ETicketDelivery'
import ETicketDeliveryMode from '../enums/ETicketDeliveryMode'
import ETicketStatus from '../enums/ETicketStatus'
import MediaService from '../services/MediaService'
import SendBleLinkRequest from '../interfaces/sendBleLinkRequest'
import SendBleLinkResult from '../interfaces/sendBleLinkResult'
import TramAnimation from '../../Profile/components/TramAnimation.vue'
import NavigatorShare from '@/Common/components/NavigatorShare.vue'
import GTMEvent from '@/Common/interfaces/GTMEvent'
import Contact from '../models/Contact'
import ContactBLE from '@/Profile/models/ContactBLE'
import FulfillmentTransaction from '../models/FulfillmentTransaction'

@Component({
  components: { TramAnimation, NavigatorShare, BLEDesktopSendLink },
  filters: {
    shortDate(d) {
      return d ? Intl.DateTimeFormat('en-US').format(new Date(d)) : ''
    },
  },
})
export default class BLEConnect extends Vue {
  @Action('trackEvent') trackEvent
  @Action('profile/fetchProfile') fetchProfileInfo
  @Action('profile/fetchContactBLE') fetchContactBLEInfo
  @Getter('settings/themeSettings') themeSettings
  @Getter('settings/languageSettings') language
  @Getter('profile/contacts') familyMembers
  @Getter('browserInfo') browserInfo
  @Getter('profile/bleContacts') familyBLEInfo
  @Getter('oidcStore/oidcUser') user
  @Mutation('setPageLoading') setPageLoading
  @State('pageLoading') pageLoading

  activationMember: any = null
  mobileShareMember: any = null
  dialog: boolean = false
  ETicketStatus: any = ETicketStatus
  notification: any = {
    visible: false,
    title: '',
    text: '',
    icon: '',
    color: null,
    timeout: 2000,
  }
  serviceError: ServiceError | null = null
  step: number = 1
  testMode: boolean = false

  get bleThemeSettings(): any {
    return this.themeSettings.theme?.bleConnect || {}
  }

  get addMobilePassInstructions(): any {
    const message =
      this.language.bleConnect.addMobilePassStepInstructions || `Add Mobile Pass for {ActivationMemberName}`
    return message.replace('{ActivationMemberName}', this.activationMemberName)
  }

  get addingNewMobilePassInstructions(): any {
    const message = this.language.bleConnect.addingPassMessage || `Addding Mobile Pass for {ActivationMemberName}`
    return message.replace('{ActivationMemberName}', this.activationMemberName)
  }

  get activateMobilePhoneInstructions(): any {
    const message =
      this.language.bleConnect.activatePhoneStepInstructions || `Ready to Activate With {ActivationMemberName}’s Phone`
    return message.replace('{ActivationMemberName}', this.activationMemberName)
  }

  get activatePhoneSubtext(): any {
    const message = this.language.bleConnect.activatePhoneSubText || `Not using {ActivationMemberName}'s Phone?</div>`
    return message.replace('{ActivationMemberName}', this.activationMemberName)
  }

  get sendMemberLink(): any {
    const message = this.language.bleConnect.sendMemberLink || `Send {ActivationMemberName} a Link`
    return message.replace('{ActivationMemberName}', this.activationMemberName)
  }

  get activatePhoneConfirmation(): any {
    const message =
      this.language.bleConnect.activatePhoneConfirmation || `Are you using {ActivationMemberName}'s Phone?`
    return message.replace('{ActivationMemberName}', this.activationMemberName)
  }

  get mobileShareTitle(): string {
    return this.language?.bleConnect?.mobileShareTitle
  }

  get mobileShareMessage(): string {
    const message =
      this.language.bleConnect.mobileShareMessage ||
      'Open this link on your phone to activate your mobile lift pass using JH Insider.'
    const member = this.mobileShareMember || this.activationMember
    return member ? message.replace('{ActivationMemberName}', member.contactInfo.firstName) : message
  }

  get canShare(): boolean {
    return !!navigator.share && this.browserInfo.isMobile
  }

  get dialogTransition(): string {
    if (this.step === 3) {
      return ``
    } else {
      return `dialog-bottom-transition`
    }
  }

  get mobileActivationUrl(): string {
    return 'https://jacksonhole.app.skilynx.com/JHIticket_initiate'
  }

  get themeShade(): string {
    return this.browserInfo.isMobile ? 'light' : 'dark'
  }

  get themeClass(): string {
    return `theme-${this.themeShade}`
  }

  get browserEnvClass(): string {
    return this.browserInfo.isMobile ? 'is-mobile' : 'is-desktop'
  }

  get activationMemberIsUser(): boolean {
    if (this.activationMember) {
      return this.activationMember.contactInfo.id === this.user?.sub
    }
    return false
  }

  get activationMemberName(): string {
    if (this.activationMember) {
      return (this.activationMember && this.activationMember.contactInfo?.firstName) || ''
    }

    return ''
  }

  get activationId(): string {
    return this.activationMember?.eticket?.id || ''
  }

  get bleContacts() {
    return this.familyMembers?.map((member: Contact) => {
      const memberBLEInfo = this.familyBLEInfo?.find((c) => c.id === member.id) || {}

      // TODO: Remove this hardcoded member ID
      return {
        contactInfo: member,
        ...memberBLEInfo,
      }
    })
  }

  get avatarColors() {
    return ['#4c648e', '#509cc6', '#f5bd65', '#b39971', '#9f432a', '#002e54', '#56602f', '#5b4938', '#cecca8']
  }

  public applyTestMode() {
    this.testMode = !this.testMode
    console.log(`BLE Test Mode [${this.testMode}]`)
    if (this.testMode) {
      const rndMember = Math.floor(Math.random() * this.familyBLEInfo.length)
      this.familyBLEInfo.map((memberBLEInfo: any, mIndex) => {
        const rndInt = Math.floor(Math.random() * 6)
        const phones = [
          'iPhone 14',
          'Google Pixel 6',
          'iPhone 13 Pro Max',
          'Samsung Galaxy S22',
          'iPhone SE',
          'iPhone 13 Pro',
        ]
        if (mIndex === rndMember && memberBLEInfo.eticket) {
          memberBLEInfo.eticket.download = {
            appVersion: '2.3',
            device: {
              deviceType: `${phones[rndInt]}`,
              deviceOS: 'iOS',
            },
            downloadDate: new Date('2022-02-22T22:41:37.5556087-07:00'),
            deliveryMode: 0,
          }
          memberBLEInfo.status = ETicketStatus.Connected
        }
      })
    }
  }

  public addMobilePass() {
    this.step = 2
    this.createETicket(this.activationMember)
  }

  public bleEvent({ eventAction, eventData = {} }) {
    const signatureEvent = {
      event: 'arrival_event',
      category: 'BLE',
      action: eventAction,
      noninteraction: false,
      ...eventData,
    }
    this.trackEvent(signatureEvent as GTMEvent)
  }

  public onShareError(err) {
    const shareAborted = err ? err.toString().toUpperCase().includes('ABORT') : false
    const message = shareAborted ? 'Send link was cancelled' : err?.toString()
    const notification = {
      title: 'Error',
      text: message,
      icon: 'error',
      color: 'error',
      timeout: 0,
      visible: true,
    }
    this.notification = {
      ...this.notification,
      ...notification,
    }
    this.mobileShareMember = null
  }

  public onShareSuccess(succ) {
    this.updateAsSent(this.mobileShareMember)

    const message = 'Send link was successful!'
    const notification = {
      title: 'Success',
      text: message,
      icon: 'success',
      color: 'success',
      visible: true,
    }
    this.notification = {
      ...this.notification,
      ...notification,
    }
    this.mobileShareMember = null
  }

  public initials(contactInfo) {
    return `${contactInfo.firstName.slice(0, 1)}${contactInfo.lastName.slice(0, 1)}`
  }

  public mobilePassAction(member) {
    const memberStatus = member.status
    let action = ''
    switch (member.status) {
      case ETicketStatus.Unknown: {
        action = 'Add Media'
        this.step = 1
        break
      }
      case ETicketStatus.Unsent: {
        action = 'Resend Link'
        this.step = 3
        break
      }
      case ETicketStatus.Sent: {
        action = 'Resend Link'
        this.step = 3
        break
      }
      default: {
        action = 'Member Action'
      }
    }

    this.activationMember = {
      ...member,
      newRecipient: null,
      newDeliveryMode: null,
    }

    this.bleEvent({ eventAction: action })
    this.dialog = true
  }

  public cancelActivation() {
    this.step = 0
    this.activationMember = null
    this.dialog = false
  }

  public activateThisPhone() {
    if (this.mobileActivationUrl && this.activationId) {
      const activateUrl = `${this.mobileActivationUrl}/${this.activationId}`
      this.cancelActivation()
      window.open(activateUrl)
    } else {
      const message = 'There was a problem activating this phone'

      const notification = {
        title: 'Error',
        text: message,
        icon: 'error',
        color: 'error',
        visible: true,
      }
      this.notification = {
        ...this.notification,
        ...notification,
      }
    }
  }

  public async mounted() {
    // In the case that this route redirected the user to sign in,we need to clean up the redirectPath in local storage
    localStorage.removeItem('redirectPath')
    await this.getBLEData()
  }

  public async getBLEData() {
    this.setPageLoading(true)
    if (!this.familyMembers) {
      await this.fetchProfileInfo()
    }

    this.familyMembers.forEach((member) => {
      this.fetchContactBLEInfo(member.id)
    })
    this.setPageLoading(false)
  }

  protected async createETicket(member: BLEContact) {
    const customerId = member?.contactInfo?.id.toString() || null

    if (customerId) {
      const createTicketResponse: FulfillmentTransaction = await MediaService.CreateEticket(customerId)
      await MediaService.ActivateEticket(createTicketResponse.transactionId)
      const newBLEInfo = await this.fetchContactBLEInfo(member?.contactInfo?.id)
      this.activationMember = {
        ...this.activationMember,
        eticket: newBLEInfo.eticket,
      }
      this.step = 3
    }
  }

  public startMobileShare(member: any) {
    this.mobileShareMember = member
    this.cancelActivation()
  }

  public async send(member: BLEContact) {
    const eTicketDelivery: ETicketDelivery = {
      deliveryMode: member.newDeliveryMode,
      recipient: member.newRecipient,
      isSent: false,
      createDateUtc: new Date(),
      updateDateUtc: new Date(),
    }

    const request: SendBleLinkRequest = {
      id: this.activationId,
      ETicketDelivery: eTicketDelivery,
    }

    const sendResult: SendBleLinkResult = await MediaService.SendBleLink(request)

    if (sendResult) {
      member.status = ETicketStatus.Sent
      member.newRecipient = null
      member.newDeliveryMode = ETicketDeliveryMode.Unknown
    }

    // Optimistically indicate this link has been sent (though it may not have been sent by SMS/Email Queue)
    const bleContact = this.bleContacts.find((c) => c.id === this.activationMember.id)
    bleContact.status = ETicketStatus.Sent

    this.cancelActivation()
  }

  public async updateAsSent(member: ContactBLE) {
    const eTicketDelivery: ETicketDelivery = {
      deliveryMode: ETicketDeliveryMode.Unknown,
      recipient: '',
      isSent: true,
      createDateUtc: new Date(),
      updateDateUtc: new Date(),
    }

    const ticketId = member.eticket?.id

    if (ticketId) {
      const request: SendBleLinkRequest = {
        id: ticketId,
        ETicketDelivery: eTicketDelivery,
      }

      const sendResult: SendBleLinkResult = await MediaService.SendBleLink(request)

      // After the sent info is updated, refresh the member's BLE info
      await this.fetchContactBLEInfo(member.id)
    }
  }
}
