







































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import type Contact from '@/Common/interfaces/Contact'
import AxBirthdayPicker from '@/Common/components/AxBirthdayPicker.vue'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

@Component({
  components: {
    AxBirthdayPicker,
  },
})
export default class FamilyMemberForm extends Vue {
  @Prop({
    required: false,
    default: () => ({
      dateOfBirth: null,
      firstName: '',
      lastName: '',
      parentContactId: '',
    }),
  })
  contact: Contact

  private contactBirthdate: moment.Moment | null = null
  private processing: boolean = false

  validations() {
    return {
      contact: {
        firstName: { required },
        lastName: { required },
        dateOfBirth: { required },
      },
    }
  }

  private onBirthdayUpdated(value) {
    this.contact.dateOfBirth = value.momentBirthday.toDate()
  }

  @Emit('onSubmitted')
  async addFamilyMember() {
    this.$v!.$touch()

    if (!this.$v!.$error) {
      this.processing = true
      const newContact = { ...this.contact }
      return newContact
    }
  }

  cancelAddFamilyMember() {
    this.$emit('onCancelled')
  }
}
