












import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Ticket extends Vue {
  @Prop({ default: null }) count: number | null
}
