































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import type PassResult from '../interfaces/passResult'
import CollectionService from '@/Common/services/CollectionService'
import Collection from '@/Common/interfaces/Collection'

@Component({
  components: {},
})
export default class PassCheckerInfoCard extends Vue {
  @Getter('settings/languageSettings') language
  @Prop({ required: true }) passResult: PassResult
  @Prop({ required: true }) date: Date
  @Prop({ required: true }) posReplacementStringCollection: Collection | null

  get resultTitle() {
    return !this.isPassValid ? this.language.passChecker.passInvalidTitle : this.language.passChecker.passValidTitle
  }

  get resultMessage() {
    return !this.isPassValid ? this.language.passChecker.passInvalidText : this.language.passChecker.passValidText
  }

  get resultStatus(): string {
    if (this.errors) {
      const lastError = this.errors[this.errors.length - 1] as any
      return lastError!.title
    }
    return ''
  }

  get statusIcon() {
    return this.isPassValid ? 'L' : 'X'
  }

  get statusClass() {
    return this.isPassValid ? 'checkmark' : 'times'
  }

  get isPassValid() {
    return this.passResult.success == true && this.passResult.errors.length < 1
  }

  get productTitle() {
    return this.passResult.product.description ?? this.language.passChecker.noProductText
  }

  get errors() {
    const errors: any[] = []
    this.passResult.errors.forEach((error) => {
      switch (error.code) {
        case '30':
          errors.push({
            title: this.language.passChecker.invalidRequestErrorTitle,
            text: this.language.passChecker.invalidRequestErrorText,
            bullets: null,
          } as any)
          break
        case '40':
          errors.push({
            title: this.language.passChecker.inactiveMediaErrorTitle,
            text: this.language.passChecker.inactiveMediaErrorText,
            bullets: null,
          } as any)
          break
        case '60':
          errors.push({
            title: this.language.passChecker.invalidProductErrorTitle,
            text: this.language.passChecker.invalidProductErrorText,
            bullets: null,
          } as any)
          break
        case '50':
          errors.push({
            title: this.language.passChecker.hostlistErrorTitle,
            text: this.language.passChecker.hostlistErrorText,
            bullets: [this.replacePosText(this.passResult.line2)],
          } as any)
          break
      }
    })
    return errors
  }

  private replacePosText(value: string) {
    if (this.posReplacementStringCollection) {
      this.posReplacementStringCollection.collectionItems.forEach((replacement) => {
        value = value.replace(replacement.name, replacement.value)
      })
    }

    return value
  }
}
