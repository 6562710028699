






























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import ContactTask from '../../Task/interfaces/ContactTask'
import VueQrcode from 'vue-qrcode'
import type PrintableMedia from '../../Task/interfaces/PrintableMedia'
import ProfileService from '../services/ProfileService'

@Component({
  components: {
    VueQrcode,
  },
})
export default class FamilyMembers extends Vue {
  @Prop({ default: null }) printableMedia: PrintableMedia

  public qrCodes: [] | any = []

  mounted() {
    this.getQrCodes()
  }

  private getQrCodes() {
    if (this.printableMedia) {
      this.printableMedia.printableMediaCustomers.forEach((c) => {
        c.customerTransaction.forEach((t) => {
          if (this.qrCodes.indexOf(t.printableMediaId) === -1) {
            this.qrCodes.push(t.printableMediaId)
          }
        })
      })
    }
  }

  private async getAppleWalletPass(
    ipCode: string,
    firstName: string,
    lastName: string,
    externalOrderId: string | null
  ) {
    await ProfileService.FetchAppleWalletPassFile(ipCode, firstName, lastName, externalOrderId)
  }
  private async getGoogleWalletPass(
    ipCode: string,
    firstName: string,
    lastName: string,
    externalOrderId: string | null
  ) {
    try {
      const googleWalletLink = await ProfileService.FetchGoogleWalletPassLink(
        ipCode,
        firstName,
        lastName,
        externalOrderId
      )
      window.open(googleWalletLink, '_blank')
    } catch (error) {
      // Error Occured
    }
  }
}
