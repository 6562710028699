var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.linkMember && _vm.linkMember.contactInfo)?_c('div',{staticClass:"ble-desktop-send-link mt-3 mb-0"},[_c('v-text-field-simplemask',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPhoneNumber),expression:"isPhoneNumber"}],ref:"phoneNumberField",attrs:{"id":"phone-input","height":"36","width":"193","label":"email or phone number","type":"number","properties":{
      outlined: true,
      dense: true,
      errorMessages: ("" + (_vm.$v.$error ? 'Not a valid phone number' : '')),
    },"options":{
      inputMask: '(###) ###-####',
      outputMask: '###########',
      empty: null,
      applyAfter: false,
    }},model:{value:(_vm.linkMember.newRecipient),callback:function ($$v) {_vm.$set(_vm.linkMember, "newRecipient", $$v)},expression:"linkMember.newRecipient"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isPhoneNumber),expression:"!isPhoneNumber"}],attrs:{"id":"email-address","height":"36","width":"193","label":"email or phone number","errorMessages":_vm.$v.$error ? 'Must be a valid email or phone number' : '',"outlined":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.sendLink.apply(null, arguments)}},model:{value:(_vm.linkMember.newRecipient),callback:function ($$v) {_vm.$set(_vm.linkMember, "newRecipient", $$v)},expression:"linkMember.newRecipient"}}),_c('v-btn',{staticClass:"mt-0 mb-4",attrs:{"color":_vm.primaryAction ? 'primary' : 'secondary',"block":"","large":""},on:{"click":_vm.sendLink}},[_vm._v("Send Link")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }