























import { Action, Getter, Mutation } from 'vuex-class'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import PassCheckerForm from '../components/PassCheckerForm.vue'
import ProfileService from '../services/ProfileService'
import store from '@/Common/store/index'
import PassResult from '../interfaces/passResult'
import CheckPassRequest from '../interfaces/CheckPassRequest'
import PassCheckerInfoCard from '../components/PassCheckerInfoCard.vue'
import CollectionService from '@/Common/services/CollectionService'
import Collection from '@/Common/interfaces/Collection'

@Component({
  components: {
    PassCheckerForm,
    PassCheckerInfoCard,
  },
})
export default class PassChecker extends Vue {
  @Getter('settings/languageSettings') language

  serviceError: ServiceError | null = null
  result: PassResult | null = null
  date: Date | null
  posReplacementStringCollection: Collection | null
  posReplacementStringCollectionId: number = 2
  isLoading: boolean = false

  public created() {
    this.getCollections()
  }

  private async getCollections() {
    const collections = await CollectionService.FetchCollections()
    this.posReplacementStringCollection = collections.filter((c) => c.id == this.posReplacementStringCollectionId)[0]
  }

  private async checkPass(request: CheckPassRequest) {
    this.isLoading = true
    this.result = null
    this.result = await ProfileService.CheckPass(request)
    this.isLoading = false
    const date = request.date
    date.setDate(date.getDate() + 1)
    this.date = date
  }
}
