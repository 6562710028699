




















































































































































import { Action, Getter, Mutation, State } from 'vuex-class'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ServiceError } from '@/Common/interfaces/ServiceError'
import Contact from '@/Common/interfaces/Contact'
import LocationModel from '@/Location/models/Location'
import type ActivityContact from '@/Activity/interfaces/ActivityContact'
import ActivityService from '@/Activity/services/ActivityService'
import TaskService from '@/Task/services/TaskService'
import store from '@/Common/store/index'
import Activity from '@/Activity/interfaces/Activity'
import ActivityTasks from '@/Activity/interfaces/ActivityTasks'
import FamilyMembers from '../components/FamilyMembers.vue'
import Overview from '../components/Overview.vue'
import type Location from '@/Location/interfaces/location'
import ItemSelectorGroup from '@/Common/components/ItemSelectorGroup.vue'
import ItemSelector from '@/Common/components/ItemSelector.vue'
import FamilyMemberForm from '../components/FamilyMemberForm.vue'
import ProfileService from '../services/ProfileService'
import PrintableMediaMembers from '../components/PrintableMediaMembers.vue'
import PrintableMedia from '../components/PrintableMedia.vue'
import Task from '@/Task/interfaces/Task'
import AppSidebar from '@/Common/components/AppSidebar.vue'
import GTMEvent from '@/Common/interfaces/GTMEvent'

@Component({
  components: {
    FamilyMembers,
    Overview,
    ItemSelector,
    ItemSelectorGroup,
    FamilyMemberForm,
    PrintableMediaMembers,
    PrintableMedia,
    AppSidebar,
  },
})
export default class Home extends Vue {
  @Action('location/fetchCurrentLocation') fetchCurrentLocation
  @Action('location/fetchEditLocation') fetchEditLocation
  @Action('location/fetchLocations') fetchAllLocations
  @Action('oidcStore/signOutOidc') signOut
  @Action('profile/fetchProfile') fetchProfileInfo
  @Action('task/advanceTask') advanceTask
  @Action('trackEvent') trackEvent
  @Getter('activity/activityContacts') activityContacts
  @Getter('activity/activityTasks') activityTasks
  @Getter('activity/selectedActivity') selectedActivity
  @Getter('activity/selectedActivityTasks') selectedActivityTasks
  @Getter('location/currentLocation') currentLocation
  @Getter('location/editLocation') editLocation
  @Getter('location/previousLocation') previousLocation
  @Getter('features') enabledFeatures
  @Getter('hasFeature') hasFeature
  @Getter('oidcStore/oidcUser') user
  @Getter('profile/contacts') familyMembers
  @Getter('settings/languageSettings') language
  @Getter('settings/themeSettings') themeSettings
  @Getter('task/contactTasks') contactTasks
  @Mutation('activity/addActivityTasks') addActivityTasks
  @Mutation('activity/setActivityContacts') setActivityContacts
  @Mutation('location/setCurrentLocation') setCurrentLocation
  @Mutation('location/setTemporaryLocation') setTemporaryLocation
  @Mutation('setPageLoading') setPageLoading
  @Mutation('task/setContactTasks') setContactTasks
  @Mutation('task/setCurrentTask') setCurrentTask
  @Mutation('task/setTasks') setTasks
  @State('pageLoading') pageLoading

  @Prop({ type: String, default: null }) location: string | null
  
  private viewAddFamilyMember() {
    this.$router.push({ name: 'add-family-member' }).catch(() => {
      // ... No catch response yet
    })
  }

  @Watch('selectedActivity')
  async onSelectedActivityChanged(value: ActivityContact, oldValue: ActivityContact) {
    if (value && !oldValue) {
      await ActivityService.FetchActivityTasks(value.activity.id).then((aTasks) => {
        const activityTasks: ActivityTasks = {
          activity: value.activity,
          tasks: aTasks,
        }
        this.addActivityTasks(activityTasks)
      })
    }
  }

  @Watch('currentLocation', { immediate: true, deep: true })
  async onLocationChanged(newLocation: Location, oldLocation: Location) {
    if (newLocation) {
      const currentLocationId = newLocation.id
      if (currentLocationId) {

        await this.fetchActivityContacts(currentLocationId)

      }
    }
  }
  

  get showBleConnect(): boolean {
    return this.$store.getters.hasFeature('BLEConnect') && this.themeSettings.theme?.bleConnect?.enableBleHomeScreen
  }

  get hasPOSCheckinTasks(): boolean {
    return this.contactTasks && this.contactTasks.checkInInfo ? this.contactTasks.checkInInfo.count > 0 : false
  }

  get defaultActivity(): ActivityContact | null {
    return this.activityContacts ? this.activityContacts[0] : null
  }

  get posTransactionsMode(): boolean {
    return this.currentLocation ? this.currentLocation.usePosTransactionsAndOrders : false
  }

  get checkInButtonLabel(): string {
    if (this.language) {
      const memberCount = this.currentLocation!.usePosTransactionsAndOrders
        ? this.contactTasks!.checkInInfo!.count
        : this.selectedContacts!.length
      const memberLabel = this.language.home!.checkInButton.replace('{MemberCount}', memberCount)
      return memberLabel
    }

    return 'Check In'
  }

  get editProfileLabel(): string {
    if (this.language) {
      const memberCount = this.selectedContacts!.length
      const memberLabel = this.language.home!.startEditProfileButton.replace('{MemberCount}', memberCount)
      return memberLabel
    }

    return 'Edit Profile(s)'
  }

  isCheckingIn: boolean = false
  serviceError: ServiceError | null = null

  public async mounted() {
    this.setPageLoading(true)
    if (!this.currentLocation) {
      await this.fetchCurrentLocation()
    }

    if (!this.editLocation) {
      await this.fetchEditLocation()
    }

    await this.fetchProfile()

    if (this.currentLocation && this.currentLocation.usePosTransactionsAndOrders) {

      await this.fetchContactTasks()

    }

    this.setPageLoading(false)
  }

  private async fetchProfile() {
    await this.fetchProfileInfo()
  }

  private async fetchActivityContacts(firstLocationId: number) {
    await ActivityService.FetchActivityContacts(firstLocationId)
      .then((actContacts: ActivityContact[]) => {
        this.setActivityContacts(actContacts)
      })
      .catch((activityContactError: ServiceError) => {
        this.setPageLoading(false)
        this.serviceError = activityContactError
      })
  }

  private onSignOut() {
    this.profileEvent({ eventAction: 'Sign Out' })
    this.signOut()
  }

  private profileEvent({ eventAction, eventData = {} }) {
    const signatureEvent = {
      event: 'arrival_event',
      category: 'Profile',
      action: eventAction,
      noninteraction: false,
      ...eventData,
    }
    this.trackEvent(signatureEvent as GTMEvent)
  }

  private editProfile() {
    this.setTemporaryLocation(this.editLocation)
    this.profileEvent({ eventAction: 'Edit Profile' })
  }

  private startTemporaryCheckIn() {
    this.setTemporaryLocation(this.currentLocation)
    this.startCheckIn()
  }

  private async startCheckIn() {
    this.isCheckingIn = true

    if (this.currentLocation.usePosTransactionsAndOrders) {
      const tasks: Task[] = []

      const allContactTasks = [
        ...(this.contactTasks.photoContactTasks || []),
        ...(this.contactTasks.rentalProfileContactTasks || []),
        ...(this.contactTasks.childRegistrationContactTasks || []),
        ...(this.contactTasks.waiverContactTasks || []),
      ]

      allContactTasks.forEach((ct) => {
        const index = tasks.findIndex((t) => t.id === ct.task.id)
        if (index === -1) {
          tasks.push(ct.task)
        }
      })

      if (tasks) {
        this.setTasks(tasks)
      }
    } else {
      await this.fetchContactTasks()
    }
    this.advanceTask()
    this.$router.push({ name: 'checkIn' }).catch(() => {
      // ... No catch response yet
    })
  }

  public async fetchContactTasks() {
    try {
      let data = this.activityContacts
      if (this.currentLocation.usePosTransactionsAndOrders) {
        data = []
      }
      const taskResult = await TaskService.FetchContactTasks(data, this.currentLocation.id)
      await this.setContactTasks(taskResult)
    } catch (tasksError) {
      this.setPageLoading(false)
      this.serviceError = tasksError
    }
  }

  get selectedContacts(): Contact[] | null {
    if (this.defaultActivity && this.defaultActivity.contacts) {
      return this.defaultActivity.contacts.filter((c) => c.isSelected)
    }

    return null
  }
}
