// Utility function for getting a range of numbers
const range = ({ start, end, ascending = true }): number[] => {
  const rangeArr: number[] = []
  if (ascending) {
    while (start <= end) {
      rangeArr.push(start++)
    }
  } else {
    while (start >= end) {
      rangeArr.push(start--)
    }
  }
  return rangeArr
}

export default range
